import { createGlobalStyle } from 'styled-components'
import { Pulse } from './keyframes/animations'
import { workOrdersColors } from 'styles/constants/workOrdersColors'

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

.pulse {
    transform: scale(1);
	animation: ${Pulse} 2s infinite;
}

.pulse.danger {
    background-color: ${({ theme }) => theme.colors.danger} !important;
    border-color: ${({ theme }) => theme.colors.danger} !important;
}

.clear {
    padding: 0px !important;
    margin: 0px !important;
}

.ant-transfer-list-body {
  overflow: auto !important;
}

.fullPortrait
{
    @media(orientation: portrait), (max-width: 768)
    {
        width: 100%;
        min-width: 100%;
    }
}

body
{
    background-color: ${({ theme }) => theme.colors.backgroundDefault} !important;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: contain;
}

.Toastify, .snackbar-wrapper {
  z-index: 999999999;
}

/* Full calendar fixes to work with our styles */
.fc-nonbusiness{
    background-color: #868686;
}
.fc-header-toolbar.fc-toolbar {
    padding-left: 10px;
    padding-right: 10px;
}
.fc-header-toolbar.fc-toolbar > div {
    display: flex;
}
.fc-header-toolbar.fc-toolbar > div > div {
    display: flex;
    align-items: center;
}
.fc-toolbar-title {
    margin: 0px 10px !important;
    font-family: 'Poppins', sans- serif;
    font-size: 1.4em !important;
}

.fc-prev-button, .fc-next-button {
    border-radius: 4px !important;
    width: 30px;
    height: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    background-color: rgba(0,0,0,0.1) !important;
    border-color: rgba(0,0,0,0.1) !important;
    color: ${({ theme }) => theme.colors.textColor} !important;
}

/* Vis timeline fixes */
.vis-item.expected {
  background: ${workOrdersColors.inExecution.background};
  border-style: dashed !important;
  border-width: 2 px;
  border-color: ${workOrdersColors.scheduled.background};
  z-index: 0;
  opacity: 1;
}

.vis-item.executed {
  opacity: 0.7;
  background: ${workOrdersColors.executed.background};
  border-color: ${workOrdersColors.executed.background};
  border-width: 2 px;
}

.vis-item.paused {
  opacity: 0.7;
  background: ${workOrdersColors.inExecution.background};
  border-color: ${workOrdersColors.inExecution.background};
  border-width: 2 px;
}

.vis-item-content {
  font-weight: bold;
  color: white;
}

.vis-timeline {
  border: 0px;
}

.dragged .item {
  opacity: 0.25;
  border-top: 1px solid #999999;
  border-bottom: 1px solid #ffffff;
}

.floating .item {
  background-color: #ffffff;
  box-shadow: 0 4px 20px #666666;
  cursor: grabbing !important;
}

.dl-item.floating {
  width: 85%;
}

.quill-editor iframe {
    pointer-events: none;
}

.modal-80w {
  width: 100vw;
  /* height: 100%; */
  max-width: 80vw;
}

.modal-95w {
  width: 100vw;
  /* height: 100%; */
  max-width: 95vw;
  min-width: 95vw;
}

div[class^="Snackbar_snackbar-wrapper"] {
  z-index: 1100;
}

.full-on-mobile {
  @media(orientation: portrait), (max-width: 768)
    {
      margin-left: 1vw;
      max-width: 100vw;
      width: auto;
      height: auto;
    }
}

.full-on-mobile .modal-content {
  @media(orientation: portrait), (max-width: 768)
    {
      width: 97vw;
      // height: 97vh;
    }
}

.full-on-mobile-scrollable {
  @media(orientation: portrait), (max-width: 768)
    {
      /* width: 97vw; */
      /* max-height: 97% !important; */
    }
}

@font-face {
      font-family: 'fontello';
      src: url('./font/fontello.eot?24194314');
      src: url('./font/fontello.eot?24194314#iefix') format('embedded-opentype'),
           url('./font/fontello.woff?24194314') format('woff'),
           url('./font/fontello.ttf?24194314') format('truetype'),
           url('./font/fontello.svg?24194314#fontello') format('svg');
      font-weight: normal;
      font-style: normal;
    }
      
    .ps-icon
    {
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: never;
     
      display: inline-block;
      text-decoration: inherit;
      width: 1em;
      margin-right: .2em;
      text-align: center;
      font-variant: normal;
      text-transform: none;
     
      line-height: 1em;
      margin-left: .2em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
     
      /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }
    
    .carousel .control-dots .dot {
      background-color: #212529;
    }

.ant-descriptions-header {
  margin-bottom: 5px !important;
}

.bm-burger-button {
  position: sticky;
  width: 20px;
  height: 20px;
  left: 36px;
  top: 36px;
  color: white;
}

.bm-overlay {
  // display: none !important;
  top: 0;
  left: 0;
}

.bm-burger-bars {
  background: #fff;
}

.bm-burger-bars-hover {
  background: #fff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

#ps-id-calendarOptions-btn-zoomOut {
  margin-left: 0px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

.bm-menu-wrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.bm-menu {
  background: #ffffff;
  padding: 1.5em 0.5em 0;
  font-size: 1.15em;
  box-shadow: 1px 1px 5px 4px rgba(0, 0, 0, .1);
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

.ant-segmented-item {
  margin-bottom: 0px !important;
}
`
