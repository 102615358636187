import React from 'react'
import { useNotification } from 'hooks'

export const withNotification = (ComponentWrapped) => {
  return (props) => {
    const [defaultNotification, successNotification, errorNotification, warningNotification] = useNotification()

    return (
      <ComponentWrapped
        defaultNotification={defaultNotification}
        successNotification={successNotification}
        errorNotification={errorNotification}
        warningNotification={warningNotification}
        {...props}
      />
    )
  }
}