import moment from 'moment-timezone'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

export function durationStringTemplate() {
  return this.duration.days() >= 1 ? 'hh:mm[+]d' : 'hh:mm'
}

export function durationToString(duration) {
  return moment.duration(duration).format(durationStringTemplate)
}

export function durationToStringShift(duration) {
  const dur = moment.duration(duration)

  // Check if duration is greater than or equal to one day
  if (dur.days() >= 1) {
    // Format with days, hours, and minutes
    return dur.format('hh:mm [+] d [(finaliza no dia seguinte)]')
  } else {
    // Format with hours and minutes
    const hours = Math.floor(dur.asHours()) // Get total hours
    const minutes = dur.minutes() // Get remaining minutes

    // Format hours and minutes with leading zeros
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')

    // Combine formatted hours and minutes
    return `${formattedHours}:${formattedMinutes}`
  }
}
